import React, { useEffect, useRef, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import WgcStateCard from "./components/WgcStateCard";
import { Table } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SaleReport from "./components/SaleReport";
import VisitInsights from "./components/VisitInsights";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { axiosInstance } from "../../utility/interceptor";

import {
  getSaleReportDate,
  getSaleReportDateByMonth,
  sortDateByLatest,
  sortDateByOld,
} from "./utils/utils";
import { ColorRing } from "react-loader-spinner";

function AffiliateDashboard() {
  const ref = useRef();
  const location = window.location.href;
  const baseUrl = location.split("/affiliate-dashboard")[0];
  const { authData } = useSelector((state) => state.auth);
  const [overallStats, setOverallStats] = useState({});
  const [salesReport, setSalesReport] = useState([]);
  const [saleDate, setSaleDate] = useState([]);
  const [saleDateByMonth, setSaleDateByMonth] = useState([]);
  const [sortedSalesData, setSortedSaleData] = useState([]);
  const [sortedSalesDataByMonth, setSortedSaleDataByMonth] = useState([]);
  const [gamesStats, setGamesStats] = useState([]);
  const [salesReportByMonth, setSaleReportByMonth] = useState([]);
  const [loading, setLoading] = useState();

  const gameOptions = [
    { value: "All Games", label: "All Games" },
    { value: "Game 2", label: "Game 2" },
    { value: "Game 3", label: "Game 3" },
  ];
  const percentage = 66;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(ref.current.innerHTML);
    toast.success("Affiliate Url Copied");
  };

  const fetchData = async (url, token, state) => {
    setLoading(true);
    axiosInstance
      .get(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        state(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(
      "/leaderboard/affiliate-stats-overall",
      localStorage.getItem("sgc-token"),
      setOverallStats
    );
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchData(
        "/leaderboard/affiliate-stats-by-interval?interval=D",
        localStorage.getItem("sgc-token"),
        setSalesReport
      );
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchData(
        "/leaderboard/affiliate-stats-by-game",
        localStorage.getItem("sgc-token"),
        setGamesStats
      );
    }, 700);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchData(
        "leaderboard/affiliate-stats-by-interval?interval=M",
        localStorage.getItem("sgc-token"),
        setSaleReportByMonth
      );
    }, 800);
  }, []);

  // calculate date for sale report graph
  useEffect(() => {
    if (salesReport) {
      let salesReportDate = getSaleReportDate(salesReport);
      setSaleDate(salesReportDate);
    }
  }, [salesReport]);

  // calculate count and earnings for sale report graph
  useEffect(() => {
    if (salesReport) {
      let array = [];
      let sortedDate = sortDateByLatest(salesReport);
      salesReport.forEach((item, i) => {
        if (i < 10) {
          array.push(sortedDate[i]);
        }
      });
      let sortedSaleReportData = sortDateByOld(array);
      setSortedSaleData(sortedSaleReportData);
    }
  }, [salesReport]);

  // calculate date for sale report graph by month
  useEffect(() => {
    if (salesReportByMonth) {
      let salesReportDate = getSaleReportDateByMonth(salesReportByMonth);

      setSaleDateByMonth(salesReportDate);
    }
  }, [salesReportByMonth]);

  // calculate count and earnings for sale report graph By month
  useEffect(() => {
    if (salesReportByMonth) {
      let array = [];
      let sortedDate = sortDateByLatest(salesReportByMonth);
      salesReport.forEach((item, i) => {
        if (i !== 0 && i < 7) {
          array.push(sortedDate[i]);
        }
      });
      let sortedSaleReportData = sortDateByOld(array);
      setSortedSaleDataByMonth(sortedSaleReportData);
    }
  }, [salesReportByMonth]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ColorRing
          visible={true}
          height="100"
          width="100"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["white", "white", "white", "white", "white"]}
        />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid affiliate-dashboard-banner">
        <div className="container">
          <div className="mb-7">
            <h1 className="banner-title mb-2 font-trajan">
              Hello, {authData.username}
            </h1>
            <p className="banner-desc mb-6">
              Legends are made everyday. May you fulfill your dreams and pride
              in your ability to deliver excellence in all aspects of life.
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <h3 className="text-white mx-2 font-trajan mb-0">Affiliate URL</h3>

            <div className="d-flex justify-content-center flex-wrap">
              <div className="affiliatede-url-link-wrapper">
                <p className="affiliatede-url-link" ref={ref}>
                  {baseUrl}/register?sponsor={authData.username}
                </p>
              </div>
              <span>
                <button
                  className="btn btn-copy-cs btn-icon"
                  onClick={copyToClipboard}
                >
                  <AiFillCopy size={24} />
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="buy-coins-why-wgc">
        <div className="container mb-4">
          <div className="row">
            <>
              <WgcStateCard
                img="/assets/icons/weekly-discount.png"
                valueTitle={overallStats.sponsor_count}
                smallTitle="Sponsor Count"
                progressBar={
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    strokeWidth={15}
                    styles={buildStyles({
                      textSize: "18px",
                      strokeLinecap: "butt",
                      pathColor: "#bf22e6",
                      trailColor: "#d39ae1",
                      textColor: "#d39ae1",
                    })}
                  />
                }
              />
              <WgcStateCard
                id="2"
                img="/assets/icons/weekly-wallet.png"
                valueTitle={overallStats.total_purchase}
                smallTitle="Total Purchase"
                progressBar={
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    strokeWidth={15}
                    styles={buildStyles({
                      textSize: "18px",
                      strokeLinecap: "butt",
                      pathColor: "#1399a7",
                      trailColor: "#9fe0e6",
                      textColor: "#9fe0e6",
                    })}
                  />
                }
              />
            </>

            <div className="col-12 py-3">
              <SaleReport
                saleDate={saleDate}
                sortedSalesData={sortedSalesData}
              />
            </div>
            <div className="col-md-7 py-3">
              <div className="card-gold-table bg-light-grey-cus">
                <div className="card-body-gold">
                  <div className="gold-table">
                    <Table hover responsive>
                      <thead>
                        <tr>
                          <th>Game</th>
                          <th>Earnings</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gamesStats.length > 0 ? (
                          gamesStats.map((game, i) => {
                            <tr key={i}>
                              <td>
                                <strong className="text-white">
                                  {game.game_title}
                                </strong>
                              </td>
                              <td>{game.earnings}</td>
                            </tr>;
                          })
                        ) : (
                          <tr>
                            <td colSpan={2}>No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 py-3">
              <VisitInsights
                saleDateByMonth={saleDateByMonth}
                sortedSalesDataByMonth={sortedSalesDataByMonth}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="buy-coins-why-wgc">
        <div className="container mb-4">
          <div className="row">
            <div className="col-12">
              <h1 className="banner-title mb-2 font-trajan text-center">
                Sponsor ID
              </h1>
              <p className="banner-desc w-75 mx-auto text-center mb-4">
                {authData.username}
              </p>
              <div className="d-flex justify-content-center flex-wrap">
                <div className="affiliatede-url-link-wrapper">
                  <p className="affiliatede-url-link" ref={ref}>
                    {baseUrl}/register?sponsor={authData.username}
                  </p>
                </div>
                <span>
                  <button
                    className="btn btn-copy-cs btn-icon"
                    onClick={copyToClipboard}
                  >
                    <AiFillCopy size={24} />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AffiliateDashboard;
