import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
function TokenomicsTabContant() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="banner-title mb-1 font-trajan">About Us</h1>
          <h4 className="mb-5 font-trajan text-white">WGC</h4>
        </div>
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                UNLEASH THE WORLD OF WGC: A DYNAMIC METAVESRE EXPERIENCE
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Welcome to the captivating realm of World Game Community (WGC),
                where innovation and community converge in the pulsating heart
                of the gaming landscape. WGC stands not only as a game publisher
                but as a living metaverse, intricately sculpted by the ardent
                gaming community it proudly serves. Operated and owned by
                dedicated gamers, WGC is more than a mission; it's a
                revolutionary movement poised to redefine the very essence of
                gaming, transforming it into a universal language that
                intricately connects individuals across the global gaming
                landscape.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                OUR MISSION: FORGING LASTING CONNECTIONS
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                At the nucleus of WGC's mission lies a profound commitment to
                elevate gaming beyond a mere form of entertainment. It's about
                fostering enduring connections that transcend the virtual realm,
                imparting invaluable life skills, and systematically dismantling
                barriers between individuals. This commitment extends beyond the
                confines of screens, actively empowering individuals, providing
                diverse opportunities, and nurturing a community deeply rooted
                in diversity and inclusivity.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                FREE-TO-PLAY, AD-FREE, AND INCLUSIVE GAMING
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                In the spirit of inclusivity, WGC pioneers a revolutionary
                approach—every WGC game is not just Free-To-Play but also
                entirely ad-free. The goal is to make gaming a genuinely
                accessible and immersive experience for everyone. Players can
                enrich their gaming journey by either purchasing in-game items
                or engaging in esports competitions using WGC credits. WGC
                transcends the mere creation of games; it aspires to establish
                an inclusive hub where meeting people from diverse backgrounds
                through games becomes the norm, fostering a sense of unity and
                camaraderie.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                UNIVERSAL METAVERSE HUB: A REALM OF LIMITLESS POSSIBILITIES
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                WGC's metaverse hub stands as a boundary-defying gaming utopia,
                democratically governed by the players, for the players. The
                gaming experience transcends conventional platforms, offering a
                multitude of possibilities. Whether embarking on epic mobile
                conquests, engaging in fierce battles against mythical beasts in
                persistent PC universes, or collaboratively addressing
                sustainability challenges through interactive simulations, WGC's
                metaverse is a canvas of limitless potential. WGC believes in
                the transferable skills learned through gaming and actively
                collaborates with organizations to provide opportunities for
                marginalized youth, seamlessly bridging the gap between the
                virtual and real worlds.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                EMPOWERING THE FUTURE: A MISSION BEYOND GAMING
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                As a testament to our commitment, WGC actively empowers the next
                generation. Our engagement goes beyond the gaming realm,
                extending to providing hands-on game design experiences through
                pro labs. By mentoring youth through industry luminaries, we aim
                to pass the torch, inspiring the game developers of tomorrow.
                Stay tuned as WGC's roadmap unfolds, featuring not just games
                but immersive experiences that welcome all seeking fellowship,
                constructive fun, and a shared passion for the boundless
                universe of gaming.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                Next-Generation Platform Features
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                The next-generation WGC platform is more than a space to play
                games. It's a dynamic ecosystem that seamlessly connects players
                across the globe, featuring:  Vibrant Profiles: Users can not
                only showcase their identities but also connect on a personal
                level, fostering meaningful interactions. The profile becomes a
                canvas for self-expression within the gaming community.
                <br />
                <br />
                Advanced Matchmaking: Algorithms predict not just enjoyable
                teammates but potential long-lasting gaming companions,
                enhancing the social aspect of gaming. It's not just about
                winning games; it's about forging friendships.
                <br />
                <br />
                Proactive Moderation: Beyond ensuring safety, proactive
                moderation creates a positive and inclusive space for all
                players. It's a commitment to fostering a welcoming environment,
                free from toxicity, and promoting healthy gaming interactions.
                <br />
                <br />
                Rewards: More than virtual accolades, rewards spur not just
                sportsmanship but a sense of global camaraderie. Recognizing and
                celebrating positive behavior goes beyond the game, contributing
                to a more connected and supportive gaming community. Building
                Bridges Beyond Gaming Gaming builds real-world skills, and WGC
                takes it a step further by creating tournaments coaching these
                talents. Partnerships with organizations not only enrich the
                gaming experience but also enable digital opportunities for
                disadvantaged groups. The ambitious roadmap features not just
                games but experiences, welcoming all seeking fellowship,
                constructive fun, and a shared passion for the gaming universe.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                OUR AMBITIOUS ROADMAP: 300+ GAMES
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                At the heart of World Game Community's (WGC) vision is an
                ambitious roadmap that transcends the boundaries of traditional
                gaming. WGC isn't just about creating a handful of games; it's a
                commitment to providing an extensive and diverse gaming
                experience that caters to a wide range of interests and
                preferences. Our roadmap is a testament to our dedication to
                delivering not just quantity but quality, ensuring that every
                game contributes to the rich tapestry of the WGC gaming
                universe.
                <br />
                <br />
                Variety Across Genres
                <br /> Our expansive roadmap features a lineup of 300+ games
                spanning various genres, ensuring that there's something for
                every type of gamer. From immersive role-playing adventures to
                adrenaline-pumping multiplayer competitions, each game is
                meticulously crafted to offer a unique and engaging experience.
                Whether you're a seasoned gamer or a casual player, our diverse
                game library is designed to cater to your gaming desires. <br />
                <br />
                Innovation in Gameplay <br />
                WGC is not content with merely replicating existing gaming
                tropes; we're committed to pushing the boundaries of innovation
                in gameplay. Our games feature cutting-edge mechanics,
                captivating storylines, and interactive elements that challenge
                traditional norms. The goal is to provide players with
                experiences that not only entertain but also push the limits of
                what gaming can achieve. <br />
                <br />
                Cross-Platform Accessibility
                <br /> In an era where gaming spans across various platforms,
                our roadmap emphasizes cross-platform accessibility. Whether you
                prefer gaming on PC, console, or mobile devices, WGC ensures
                that our games are seamlessly accessible, allowing you to enjoy
                the gaming experience on your preferred platform without
                compromising quality or features. <br />
                <br />
                Community-Driven Development
                <br /> WGC believes in the power of community, and our roadmap
                reflects this philosophy. As we roll out new games, we actively
                seek input and feedback from our player community. This
                collaborative approach ensures that our games resonate with the
                desires and expectations of the very community they are designed
                for. From beta testing to post-launch updates, the WGC community
                plays a vital role in shaping the gaming landscape.
                <br />
                <br /> Esports and Competitive Gaming
                <br /> For the competitive spirits among us, WGC's roadmap
                includes a robust esports ecosystem. We're not just creating
                games; we're building arenas for intense competition, where
                players can showcase their skills on a global stage.
                Tournaments, leagues, and competitive events are integral parts
                of our roadmap, fostering a culture of sportsmanship and
                camaraderie among players. <br />
                <br />
                Continuous Evolution
                <br /> The gaming industry is dynamic, and so is our roadmap. We
                understand that player preferences, technological advancements,
                and industry trends evolve over time. That's why our roadmap is
                not static; it's a living document that adapts to the
                ever-changing landscape of gaming. Regular updates, expansions,
                and new game releases are part of our commitment to keeping the
                WGC gaming experience fresh and exciting. <br />
                <br />
                Inclusive Gaming Experiences
                <br /> Our roadmap places a strong emphasis on inclusivity. We
                believe that gaming should be a welcoming space for players of
                all backgrounds, and our diverse game offerings reflect this
                commitment. Whether you're a solo player, a co-op enthusiast, or
                a fan of massive multiplayer experiences, WGC's roadmap ensures
                that everyone finds their place in our gaming universe.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                VALUES & PHILOSOPHY
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                - Gaming transcends divisions, spurring human progress when used
                conscientiously. It's not just a pastime; it's a powerful force
                that can shape societies positively.
                <br />- Gaming acts as the universe's greatest connector,
                revealing shared essence and unlocking camaraderie despite
                earthly barriers. It's a common language that goes beyond
                demographics, fostering a sense of belonging.
                <br />
                <br />
                Vision
                <br />- A vision of an equitable future transformed by gaming’s
                power to nurture compassion and community. It's not just about
                playing games; it's about building a better world through shared
                experiences. <br />- Fostering a gaming utopia governed by the
                players, for the players. It's a commitment to giving the gaming
                community a voice in shaping the future of WGC.
                <br />
                <br />
                Mission <br />- Empowering people to access gaming’s benefits -
                joy, personal growth, and enduring human connections. Gaming is
                not just about entertainment; it's a tool for personal
                development and building lasting connections. <br />- Focusing
                this power responsibly via strict governance upholding
                accessibility, safety, and sustainability. It's a commitment to
                ensuring that gaming remains a positive and enriching experience
                for everyone involved. <br />
                <br />
                Mandate
                <br />- Governing based on radical accessibility, positive
                community norms, proactive moderation, and sustainability. It's
                not just about creating games; it's about creating a gaming
                ecosystem that reflects and upholds values that matter. <br />-
                A commitment to principles that are not just guidelines but
                embedded from the code to hardware. It's a holistic approach to
                ensuring that every aspect of WGC, from the games to the
                platform, aligns with the values of inclusivity, safety, and
                sustainability.
                <br />
                <br /> Governance <br />- A commitment to transparency and
                inclusion informs all policies, products, and partnerships.
                Transparency is not just a buzzword; it's a fundamental
                principle that guides every decision made by WGC. <br />- All
                members actively participate in steering initiatives through
                regular open elections shaping the roadmap. It's not just a
                gaming platform; it's a community-driven space where every
                member has a say in the direction WGC takes. <br />
                <br />
                Voting Power <br />- Leveraging blockchain for election
                integrity, all members directly shape governance policies and
                product experiences via regular votes and sentiment polls.
                Blockchain is not just a technology; it's a tool for ensuring
                that the community's voice is heard and respected in every
                decision.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                TOKENOMICS
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                Interoperability Focus <br />- Cross-chain compatibility (Layer
                1 + hybrid). WGC's tokens are not confined to a single chain;
                they are designed to seamlessly interact across various
                blockchain networks, ensuring flexibility and accessibility.{" "}
                <br />- Seamless integration with all coins. The
                interoperability extends beyond chains; it's about creating a
                cohesive digital economy where WGC tokens integrate effortlessly
                with other cryptocurrencies.
                <br />
                <br /> Token Metrics <br />- Total: 10 billion tokens. The total
                token supply is not arbitrary; it's a carefully calculated
                number designed to meet the needs of the gaming community and
                the broader digital economy. <br />- Circulating: 100 million
                tokens. The circulating supply reflects a commitment to
                controlled distribution, ensuring stability and value for WGC
                tokens.
                <br />
                <br /> Optimized for Versatility and Broad Adoption
                <br />
                <br /> WGC's tokenomics are meticulously designed for
                versatility and broad adoption, ensuring a seamless and
                inclusive experience for the entire gaming community. It's not
                just about tokens; it's about creating a digital currency that
                resonates with the ethos of the gaming universe. The tokens
                serve as a dynamic catalyst for in-game transactions, esports
                competitions, and community governance, aligning with the values
                of WGC and its diverse player base.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default TokenomicsTabContant;
