import React, { useEffect, useRef, useState } from "react";
import BuyCoinsCard from "./components/BuyCoinsCard";
import { AiFillCopy } from "react-icons/ai";
import WgcStateCard from "./components/WgcStateCard";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { axiosInstance } from "../../utility/interceptor";
import { ColorRing } from "react-loader-spinner";
import moment from "moment";
import nFormatter from "./utilis/nFormatter";
import { FaPen } from "react-icons/fa";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { authUserAction } from "../../redux/slices/AuthSlice";

function UserProfile() {
  const defaultCropValue = {
    height: 271,
    unit: "px",
    width: 271,
    x: 74,
    y: 10,
  };
  const { authData } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState();
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const ref = useRef();
  const [profileStats, setProfileStats] = useState({});
  const location = window.location.href;
  const baseUrl = location.split("/profile")[0];
  const [isCropImageModalOpen, setIsCropImageModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [crop, setCrop] = useState(defaultCropValue);
  const [cropImage, setCropImage] = useState(defaultCropValue);
  const [selectedImage, setSelectedImage] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);
  const dispatch = useDispatch();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(ref.current.innerHTML);
    toast.success("Affiliate Url Copied");
  };

  const fetchStats = async (token) => {
    setLoading(true);
    axiosInstance
      .get("/profile/stats", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProfileStats(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTransactionHistory = async (token) => {
    setLoading(true);
    axiosInstance
      .get(
        `/profile/transaction-history?pageSize=10&pageIndex=${parseInt(
          pageIndex
        )}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTransactionHistory(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const validateImage = (e) => {
    const image = e.target.files;
    if (image.length < 1) return;
    if (!image[0].name.match(/\.(jpg|jpeg|png)$/i)) {
      toast.error("Invalid file type, we accept only jpg , jpeg , png  ");
      return;
    }

    // set preview image
    setPreviewImage(URL.createObjectURL(image[0]));

    // open modal
    setIsCropImageModalOpen(true);

    setSelectedImage(image[0]);
  };

  const handleOnCropChange = (crop) => {
    setCrop(crop);
  };

  const closeModal = () => {
    setCrop(defaultCropValue);
    setCropImage(defaultCropValue);
    setPreviewImage("");
    setIsCropImageModalOpen(false);
    setSelectedImage("");
  };

  const uploadImageHandler = async () => {
    const formData = new FormData();
    formData.append("data", selectedImage);
    formData.append("x", cropImage.x);
    formData.append("y", cropImage.y);
    formData.append("width", cropImage.width);
    formData.append("height", cropImage.height);

    setIsImageUploading(true);
    axiosInstance
      .post(`/profile/avatarimage`, formData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("sgc-token")}`,
        },
      })
      .then(({ data }) => {
        if (data.imageurl) {
          toast.success("Image uploaded successfully");

          closeModal();
          getUserInfo();
        }
      })
      .catch((error) => {
        if (error.response?.data?.Message) {
          toast.error(error.response?.data?.Message);
        } else {
          toast.error("server error try later");
        }
      })
      .finally(() => {
        setIsImageUploading(false);
      });
  };

  // user basic info
  const getUserInfo = async () => {
    axiosInstance
      .get("/profile/basicinfo", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("sgc-token")}`,
        },
      })
      .then((res) => {
        localStorage.setItem("userdata-sgc", JSON.stringify(res.data));
        dispatch(authUserAction(res.data));
      });
  };

  useEffect(() => {
    fetchTransactionHistory(localStorage.getItem("sgc-token"));
  }, [pageIndex]);

  useEffect(() => {
    fetchStats(localStorage.getItem("sgc-token"));
    getUserInfo();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ColorRing
          visible={true}
          height="100"
          width="100"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["white", "white", "white", "white", "white"]}
        />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid prifile-banner">
        <div className="container">
          <div>
            <div className="user-profile-icon-wrapper">
              <img
                src={
                  authData?.avatarurl
                    ? authData?.avatarurl
                    : "/assets/images/testimonial-slid-img.png"
                }
                className="img-fluid"
                style={{
                  width: "120px",
                  height: "120px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                alt="avatar"
              ></img>
              <input
                type="file"
                id="file-input"
                accept="image/png, image/jpeg, image/jpg"
                onChange={validateImage}
              />
              <label for="file-input" className="file-input-label">
                <FaPen />
                <span></span>
              </label>
            </div>
            <h6 className="user-name-show">{authData.username}</h6>
          </div>
          <h1 className="banner-title mb-1 font-trajan">My Account</h1>
          <p className="banner-desc ">
            World Game Community User Identity and features.
          </p>
        </div>
      </div>
      <div className="buy-coins-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <BuyCoinsCard
              badgeValue="Buy"
              cardImg="/assets/images/buy-icon.png"
              packageName="Bronze"
              packagePrice="$1"
            />

            <BuyCoinsCard
              badgeValue="Withdraw"
              cardImg="/assets/images/sell-icon.png"
              packageName="Silver"
              packagePrice="$100"
            />

            <BuyCoinsCard
              badgeValue="Send"
              cardImg="/assets/images/send-icon.png"
              packageName="Gold"
              packagePrice="$500"
            />

            <BuyCoinsCard
              badgeValue="Receive"
              cardImg="/assets/images/receive-icon.png"
              packageName="Platinum"
              packagePrice="$1,000"
              authData={authData}
            />
          </div>
        </div>
      </div>
      <div className="buy-coins-why-wgc">
        <div className="container mb-4">
          <div className="row">
            <div className="col-12">
              <h1 className="banner-title mb-2 font-trajan text-center">
                Affiliate URL
              </h1>
              <p className="banner-desc w-75 mx-auto text-center mb-4">
                Use the link to Affilate the team with the WGC<br></br>{" "}
                Affiliate Marketing Plan
              </p>
              <div className="d-flex justify-content-center flex-wrap">
                <div className="affiliatede-url-link-wrapper">
                  <p className="affiliatede-url-link" ref={ref}>
                    {baseUrl}/register?sponsor={authData.username}
                  </p>
                </div>
                <span>
                  <button
                    className="btn btn-copy-cs btn-icon"
                    onClick={copyToClipboard}
                  >
                    <AiFillCopy size={24} />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buy-coins-why-wgc">
        <div className="container mb-4">
          <div className="row">
            <div className="col-12">
              <h1 className="banner-title mb-5 font-trajan text-center">
                WGC Stats
              </h1>
            </div>
            <>
            <WgcStateCard
                wgc={true}
                img="/assets/icons/game-earning.png"
                valueTitle={nFormatter(authData.gems)}
                smallTitle="WGC Balance"
              />
              <WgcStateCard
                img="/assets/icons/revenue-icon.png"
                valueTitle={nFormatter(profileStats.games_played)}
                smallTitle="Games Played"
              />
              <WgcStateCard
                img="/assets/icons/revenue-icon.png"
                valueTitle={nFormatter(profileStats.games_won)}
                smallTitle="Games Won"
              />

              <WgcStateCard
                img="/assets/icons/affiliate-icon.png"
                valueTitle={nFormatter(profileStats.sponsor_count)}
                smallTitle="Affiliates Sponsored"
              />

              <WgcStateCard
                wgc={true}
                img="/assets/icons/game-earning.png"
                valueTitle={nFormatter(profileStats.total_sgc_won)}
                smallTitle="Game Winnings"
              />
              <WgcStateCard
                wgc={true}
                img="/assets/icons/revenue-icon.png"
                valueTitle={nFormatter(profileStats.retail_bonus_earned)}
                smallTitle="Affiliate Bonus Earned"
              />
            </>
            <div className="col-12 mt-5">
              <div className="card-gold-table">
                <div className="card-body-gold">
                  <div className="gold-table">
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Game</th>
                          <th>Participants</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionHistory.length > 0 ? (
                          transactionHistory.map((history, i) => {
                            return (
                              <tr key={i + 1}>
                                <td>{history.type ? history.type : "N/A"}</td>
                                <td>
                                  <strong className="text-white">
                                    {history.game ? history.game : "N/A"}
                                  </strong>
                                </td>
                                <td>
                                  {history?.participants === null
                                    ? "N/A"
                                    : history?.participants.length > 0 &&
                                      history?.participants.map((item, i) => {
                                        let people = "";
                                        if (
                                          i ===
                                          history?.participants.length - 1
                                        ) {
                                          people = item.user_name;
                                        } else {
                                          people = item.user_name + ",";
                                        }
                                        return people;
                                      })}
                                </td>
                                <td>
                                  {history.amount < 0 ? (
                                    <span className="text-danger">
                                      {history.amount} WGC
                                    </span>
                                  ) : (
                                    <span className="text-success">
                                      {history.amount} WGC
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {history?.issue_date
                                    ? moment(history?.issue_date).format(
                                        "MM-DD-YY"
                                      )
                                    : "N/A"}
                                </td>
                                <td>
                                  {history?.pool_details?.start_time
                                    ? moment(
                                        history?.pool_details?.start_time
                                      ).format("MM-DD-YY hh:mm:ss")
                                    : "N/A"}
                                </td>
                                <td>
                                  {history?.pool_details?.end_time
                                    ? moment(
                                        history?.pool_details?.end_time
                                      ).format("MM-DD-YY hh:mm:ss")
                                    : "N/A"}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7}> no WGC found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {/* pagination  */}
                    {transactionHistory.length > 0 && (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div>
                          {pageIndex !== 0 && (
                            <button
                              onClick={() => {
                                setPageIndex((prev) => prev - 1);
                              }}
                              type="button"
                              style={{
                                background: "transparent",
                                border: "1px solid #f5d323",
                                color: "#f5d323",
                                height: "2.5rem",
                                marginRight: "1rem",
                                width: "8rem",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#f5d323"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M15 18l-6-6 6-6" />
                              </svg>
                              previous
                            </button>
                          )}
                          {transactionHistory.length >= 10 && (
                            <button
                              onClick={() => {
                                setPageIndex((prev) => prev + 1);
                              }}
                              type="button"
                              style={{
                                background: "transparent",
                                border: "1px solid #f5d323",
                                color: "#f5d323",
                                height: "2.5rem",
                                width: "8rem",
                              }}
                            >
                              Next
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#f5d323"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M9 18l6-6-6-6" />
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* crop image modal  */}
      <Modal
        show={isCropImageModalOpen}
        onHide={closeModal}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
        className=""
      >
        <form>
          <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
          <Modal.Body className="px-5 pb-5">
            <div>
              <div className="form-group mb-4">
                <ReactCrop
                  crop={crop}
                  aspect={1 / 1}
                  onChange={handleOnCropChange}
                  onComplete={(c) => setCropImage(c)}
                >
                  <img
                    src={previewImage}
                    style={{
                      width: "400px",
                      height: "300px",
                      objectFit: "cover",
                    }}
                    alt="preview"
                  />
                </ReactCrop>
              </div>

              <div className="form-group mb-4">
                <button
                  type="button"
                  className="btn btn-dark rounded-0 w-100 btn-lg"
                  onClick={uploadImageHandler}
                >
                  Upload Image
                  {isImageUploading && (
                    <ColorRing
                      visible={true}
                      height="30"
                      width="30"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={["white", "white", "white", "white", "white"]}
                    />
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
}

export default UserProfile;
