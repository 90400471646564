import React from "react";
import { Link } from "react-router-dom";
const DownloadPlaySection = () => {
  return (
    <>
      <div className="download-play-section">
        <div className="row home-page-banner-card justify-content-center">
          <div className="col-md-4 home-page-banner-card-inner py-2">
            <div className="home-page-card-new mb-2">
              <img
                src="/assets/images/add-money.png"
                className="home-page-banner-card-img img-fluid"
                alt="wgc-thumbnial"
              />
            </div>
            <div>
              <h6 className="text-warning text-uppercase font-trajan fw-600">
                Add Money
              </h6>
            </div>
          </div>
          <div className="col-md-4 home-page-banner-card-inner py-2">
            <div className="home-page-card-new mb-2">
              <img
                src="/assets/images/play-win.png"
                className="home-page-banner-card-img img-fluid"
                alt="wgc-thumbnial"
              />
            </div>
            <div>
              <h6 className="text-warning text-uppercase font-trajan fw-600">
                play and win
              </h6>
            </div>
          </div>
          <div className="col-md-4 home-page-banner-card-inner py-2">
            <div className="home-page-card-new mb-2">
              <img
                src="/assets/images/cash-out.png"
                className="home-page-banner-card-img img-fluid"
                alt="wgc-thumbnial"
              />
            </div>
            <div>
              <h6 className="text-warning text-uppercase font-trajan fw-600">
                cash-out
              </h6>
            </div>
          </div>
        </div>
        <img
          src="/assets/images/Layer34.png"
          className="img-fluid download-play-section-banner"
          alt="download background"
        ></img>
        <div className="containers">
          <div
            className="d-flex download-area-imgs"
            style={{ overflow: "hidden" }}
          >
            <div className="character-postion char-2">
              <img
                src="/assets/images/character2.png"
                className="img-fluid"
                alt="charator animate"
              ></img>
            </div>
            <div className="character-postion char-3">
              <img
                src="/assets/images/character3.png"
                className="img-fluid"
                alt="charator animate"
              ></img>
            </div>
            <div className="character-postion char-1">
              <img
                src="/assets/images/character1.png"
                className="img-fluid"
                alt="charator animate"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="download-play-section-text">
        <div className="container">
          <div className="download-play-section-up">
            <h1 className="banner-title download-play-title font-trajan  mb-4">
              Download and <br></br> play our game
            </h1>
            <p className="banner-desc text-center mb-4">
              WGC The Gaming Platform for the people<br></br> who want to Play
              and Earn
            </p>
            <div className="text-center">
              <Link to="/games" className="btn btn-warning-bg btn-primary-cs ">
                More Games
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadPlaySection;
