import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import Header from "./components/Header";
import About from "./pages/about/About";
import HomeContactUs from "./components/HomeContactUs";
import Games from "./pages/games/Games";
import GameInside from "./pages/games/GameInside";
import BuyCoins from "./pages/buyCoins/BuyCoins";
import AffilatePage from "./pages/affiliate/AffilatePage";
import CareerPage from "./pages/career/CareerPage";
import DownloadGame from "./pages/games/DownloadGame";
import UserProfile from "./pages/profile/UserProfile";
import PaymentDecline from "./pages/payment-decline/PaymentDecline";
import AffiliateDashboard from "./pages/AffiliateDashbaord/AffiliateDashbaord";
import PaymentSuccess from "./pages/payment-success/PaymentSuccess";
import TermsAndServies from "./pages/termsAndServies/TermsAndServies";
import QuestionAnswer from "./pages/Q&A/Q&A";
function MainRaoutes() {
  return (
    <div>
      <Header />

      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-service" element={<TermsAndServies />} />
          <Route path="/q&a" element={<QuestionAnswer />} />
          <Route path="/register">
            <Route path="?sponsor=:admin" element={<Home />} />
            <Route path="" element={<Home />} />
          </Route>
          <Route path="/about" element={<About />} />
          <Route path="/games" element={<Games />} />
          <Route path="/games/:gamename" element={<GameInside />} />
          <Route path="/buy-credits" element={<BuyCoins />} />
          <Route path="/affiliate" element={<AffilatePage />} />
          <Route
            path="/affiliate-dashboard"
            element={
              <ProtectedRoute>
                <AffiliateDashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/download-game" element={<DownloadGame />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                {" "}
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route path="/payment-decline" element={<PaymentDecline />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
        </Routes>
        {/* <Footer /> */}
        <div className="home-contact-section" id="home-contact-section">
          <HomeContactUs />
        </div>
        {/* <div className="footer-section">
          <Footer />
        </div> */}
      </div>
    </div>
  );
}

export default MainRaoutes;

const ProtectedRoute = ({ children }) => {
  const isLoginUser = isAuthenticate();

  if (!isLoginUser) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const isAuthenticate = () => {
  return localStorage.getItem("sgc-token") ? true : false;
};
