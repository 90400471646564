import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import TokenomicsTabContant from "./TokenomicsTabContant";
import WorldGameCouncilTabContant from "./WorldGameCouncilTabContant";
function AboutTabs() {
  return (
    <>
      <div className="">
        <Tabs
          defaultActiveKey="tokenomics"
          // id="justify-tab-example"
          className="tabs-margin-cs container"
        >
          <Tab
            eventKey="tokenomics"
            title="Tokenomics"
            className="tabs-section container"
          >
            <TokenomicsTabContant />
          </Tab>
          <Tab
            eventKey="worldGameCouncil"
            title="World Game Council"
            className="tabs-section container"
          >
            <WorldGameCouncilTabContant />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default AboutTabs;
