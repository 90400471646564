import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toggleModal } from "../../redux/slices/AuthSlice";
import TestimonialSlider from "./components/TestimonialSlider";
function AffilatePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin: isUserLogin } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isUserLogin) {
      navigate("/affiliate-dashboard");
    }
  }, [isUserLogin]);

  return (
    <>
      <div className="container-fluid affilate-banner">
        <div className="container">
          <div className="mb-6">
            <Link
              onClick={() => dispatch(toggleModal(true))}
              to="#"
              className="btn btn-warning-bg btn-primary-cs "
              style={{ padding: "18px 58px", fontSize: "22px" }}
            >
              JOIN NOW!
            </Link>
          </div>
          <h1 className="banner-title mb-2 font-trajan">Affiliate Program</h1>
          <p className="banner-desc mb-6">
            Start earning by promoting technology that can make a positive impact on people's lives.
          </p>
          <div className="arrow-down">
            <img
              src="/assets/icons/scroll-download.png"
              className="img-fluid mb-5"
              width="40px"
              onClick={() => window.scrollTo(0, 800)}
              alt="wgc-thumbnial"
            ></img>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="container">
          <div className="row w-75 mx-auto">
            <div className="col-12 text-center py-6">
              <h1 className="banner-title mb-2 font-trajan">WHY WGC?</h1>
              <p className="banner-desc w-75 mx-auto text-center">
                Our affiliate partners get access to a board catalog of products
                from World Game family of brands, generous commissions on
                larger-value orders and excellent support every step of the way.
              </p>
            </div>
            <div className="col-md-4">
              <div className="buy-coin-inner-card mb-3">
                <div className="text-center buy-coin-package-img">
                  <img
                    src="/assets/images/affiate-standard-commision.png"
                    className="img-fluid"
                    alt="wgc-thumbnial"
                  />
                </div>
                <div>
                  <h5 className="text-warning text-center border-after-text p-relative">
                    Standard
                    <br /> Commision*
                  </h5>
                  <p className="wgc-value">10~15%</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="buy-coin-inner-card mb-3">
                <div className="text-center buy-coin-package-img">
                  <img
                    src="/assets/images/avg-order-value_03.png"
                    className="img-fluid"
                    alt="wgc-thumbnial"
                  />
                </div>
                <div>
                  <h5 className="text-warning text-center border-after-text p-relative">
                    Average Order <br /> Volume
                  </h5>
                  <p className="wgc-value">$125</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="buy-coin-inner-card mb-3">
                <div className="text-center buy-coin-package-img">
                  <img
                    src="/assets/images/third-box_03.png"
                    className="img-fluid"
                    alt="wgc-thumbnial"
                  />
                </div>
                <div>
                  <h5 className="text-warning text-center border-after-text p-relative">
                    Day
                    <br /> Cookies
                  </h5>
                  <p className="wgc-value">30</p>
                </div>
              </div>
            </div>

            {/* <div className="text-center">
              <img 
                src="/assets/images/coming-soon.png"
                className="img-fluid mb-5"
                width="150px"
              ></img>
              <h1 className="banner-title font-trajan coming-soon-title mb-5">
                Coming Soon!
              </h1>
            </div> */}
          </div>
        </div>
      </div>
      <div className="best-moba-League-of-Legends-banner pb-4 mb-0">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-6 text-center">
              <h1 className="banner-title mb-5 font-trajan text-left">
                How it Works ?
              </h1>
              <div className="text-left d-flex mb-4">
                <div>
                  <span>
                    <BsArrowRight size="24" className="me-3 arrow-size" />
                  </span>
                </div>
                <div>
                  <h5 className="title-with-arrow">Join The Program</h5>
                  <p className="banner-desc mx-auto text-left">
                    Simply use the link below to apply for our affiliate program
                    and become an influencer who makes positive impact.
                  </p>
                </div>
              </div>
              <div className="text-left d-flex mb-4">
                <div>
                  <span>
                    <BsArrowRight size="24" className="me-3 arrow-size" />
                  </span>
                </div>
                <div>
                  <h5 className="title-with-arrow">Promote The Products</h5>
                  <p className="banner-desc mx-auto text-left">
                    Once accepted, you will get direct access to a robust
                    product catalog, seasonal promotions, and specially-designed
                    banners and text links.
                  </p>
                </div>
              </div>
              <div className="text-left d-flex">
                <div>
                  <span>
                    <BsArrowRight size="24" className="me-3 arrow-size" />
                  </span>
                </div>
                <div>
                  <h5 className="title-with-arrow">Start Earning</h5>
                  <p className="banner-desc mx-auto text-left">
                    Earn high commissions and set yourself up for future
                    bonuses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img
                src="/assets/images/side-img.png"
                className="img-fluid"
                alt="wgc-thumbnial"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="banner-title mb-2 font-trajan text-center">
                Meet Some Of Our Affiliates
              </h1>
            </div>
            <div className="col-12 mb-6">
              <TestimonialSlider />
            </div>
          </div>
          <div className="row justify-content-center mt-4" style={{'display':'none'}}>
            <div className="col-md-10">
              <div className="col-12">
                <h1 className="banner-title mb-2 font-trajan text-center mb-3">
                  Frequently Asked Questions
                </h1>
                <div className="row">
                  <div className="col-md-6 py-4">
                    <div className="career-card h-100  p-4">
                      <div className="mb-3">
                        <h2 className="job-title font-trajan mb-0">
                          Do I need to make a payment ?
                        </h2>
                      </div>
                      <div className="mb-4">
                        <p className="text-center text-white">
                          While designing residential property and great
                          emphasis is placed on landscaping design and trasport
                          accesie environment that comfortable conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 py-4">
                    <div className="career-card h-100  p-4">
                      <div className="mb-3">
                        <h2 className="job-title font-trajan mb-0">
                          Do I need to make a payment ?
                        </h2>
                      </div>
                      <div className="mb-4">
                        <p className="text-center text-white">
                          While designing residential property and great
                          emphasis is placed on landscaping design and trasport
                          accesie environment that comfortable conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 py-4">
                    <div className="career-card h-100  p-4">
                      <div className="mb-3">
                        <h2 className="job-title font-trajan mb-0">
                          Do I need to make a payment ?
                        </h2>
                      </div>
                      <div className="mb-4">
                        <p className="text-center text-white">
                          While designing residential property and great
                          emphasis is placed on landscaping design and trasport
                          accesie environment that comfortable conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 py-4">
                    <div className="career-card h-100  p-4">
                      <div className="mb-3">
                        <h2 className="job-title font-trajan mb-0">
                          Do I need to make a payment ?
                        </h2>
                      </div>
                      <div className="mb-4">
                        <p className="text-center text-white">
                          While designing residential property and great
                          emphasis is placed on landscaping design and trasport
                          accesie environment that comfortable conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-4 text-center ">
                    <Link
                      to="/q&a"
                      onClick={() => window.scrollTo(0, 0)}
                      className="btn btn-warning rounded-0"
                    >
                      Still have a question ?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="join-affiliate-program-wrapper pb-4 mb-0">
        <div className="container">
          <div className="row mb-5 align-items-center">
            <div className="col-md-6 text-center">
              <h1 className="banner-title mb-5 font-trajan text-left">
                Join The WGC
                <br />
                Affiliate Program
              </h1>
              <div className="text-left d-flex mb-4">
                <p className="banner-desc mx-auto text-left">
                  Simply use the link to apply through our affiliate program
                  vendor, Impact.
                </p>
              </div>
              <div className="text-left">
                <button
                  className="btn btn-warning-bg btn-primary-cs"
                  style={{ padding: "18px 58px", fontSize: "22px" }}
                  onClick={() => dispatch(toggleModal(true))}
                >
                  JOIN NOW !
                </button>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img
                src="/assets/images/join-affiliate-progarm-img.png"
                className="img-fluid"
                alt="wgc-thumbnial"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AffilatePage;
