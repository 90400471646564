import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function GameSliderSingle(props) {
  const allGames = props.allGames;

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {allGames.map((game) => {
          return (
            <div key={game.id}>
              <div className="slide-style">
                <Link to={`/games/${game.publicurl}`}>
                  <img
                    src={game.thumbnail}
                    className="img-fluid"
                    alt={game.title}
                    style={{
                      height: "150px",
                      objectFit: "cover",
                      width: "100%",
                    }}
                  ></img>
                </Link>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
}

export default GameSliderSingle;
