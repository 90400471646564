import React from "react";

function WgcStateCard(Props) {
  return (
    <>
      <div className="col py-2">
        <div className="wgc-card h-100 d-flex align-items-center flex-wrap  px-2 py-2">
          <div className="d-flex align-items-center justify-content-between flex-wrap w-100">
            <div>
              <img
                className="img-fluid"
                width="50px"
                src={Props.img}
                alt="wgc"
              ></img>
            </div>
            <div>
              <h2>
                {Props.valueTitle}{" "}
                <small style={{ fontSize: "16px" }}>
                  {Props.wgc && "WGC"}
                </small>
              </h2>
              <p className="mb-0">{Props.smallTitle}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WgcStateCard;
