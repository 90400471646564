import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { getAllGames } from "../services/gameService";
import { ColorRing } from "react-loader-spinner";
function GameSlider() {
  const [loading, setLoading] = useState(false);

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings3 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rtl: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [allGames, setAllGames] = useState([]);
  const getAllGamesCall = async () => {
    setLoading(true);

    var { data } = await getAllGames(100);
    setAllGames(data);

    setLoading(false);
  };
  useEffect(() => {
    getAllGamesCall();
  }, []);

  return (
    <>
      <div className="container-fluid">
        {loading && (
          <ColorRing
            visible={true}
            height="100"
            width="100"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["white", "white", "white", "white", "white"]}
          />
        )}
        <Slider {...settings}>
          {allGames
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => {
              return (
                <div key={value.id}>
                  <div className="slide-style">
                    <Link to={`/games/${value.publicurl}`}>
                      <img
                        src={value.thumbnail}
                        className="img-fluid"
                        alt="wgc-thumbnial"
                      ></img>
                    </Link>
                  </div>
                </div>
              );
            })}
        </Slider>
        <Slider {...settings2}>
          {allGames.map((items) => {
            return (
              <div key={items.id}>
                <div className="slide-style">
                  <Link to={`/games/${items.publicurl}`}>
                    <img
                      src={items.thumbnail}
                      className="img-fluid"
                      alt="wgc-thumbnial"
                    ></img>
                  </Link>
                </div>
              </div>
            );
          })}
        </Slider>
        <Slider {...settings3}>
          {allGames
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => {
              return (
                <div key={value.id}>
                  <div className="slide-style">
                    <Link to={`/games/${value.publicurl}`}>
                      <img
                        src={value.thumbnail}
                        className="img-fluid"
                        alt="wgc-thumbnial"
                      ></img>
                    </Link>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
}

export default GameSlider;
