import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { AiFillCopy } from "react-icons/ai";
import { GrCircleAlert } from "react-icons/gr";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { authUserAction } from "../../../redux/slices/AuthSlice";
import { getUserInfo, withDrawCoins } from "../apiCall/profile";

import KycVerification from "./KycVerification";

// error form style

const errorStyle = {
  color: "red",
  fontSize: "14px!important",
};

function BuyCoinsCard(Props) {
  const { authData } = useSelector((state) => state.auth);
  const copyRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [showSellCoinsModal, setShowSellCoinsModal] = useState(false);
  const handleCloseSellCoins = () => {
    reset();
    setShowSellCoinsModal(false);
  };
  const handleShowSellCoinsModal = () => setShowSellCoinsModal(true);
  const dispatch = useDispatch();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyRef.current.value);
    toast.success("username Copied");
  };
  const [showKYCVerifycationModal, setShowKYCVerifycationModal] =
    useState(false);
  const handleCloseKYCVerifycationModal = () => {
    fetchUserProfile();
    setShowKYCVerifycationModal(false);
  };
  const handleShowKYCVerifycationModal = () => {
    setShowSellCoinsModal(false);
    setShowKYCVerifycationModal(true);
  };

  const fetchUserProfile = async () => {
    getUserInfo(localStorage.getItem("sgc-token"))
      .then((res) => {
        localStorage.setItem("userdata-sgc", JSON.stringify(res.data));
        dispatch(authUserAction(res.data));
      })
      .catch((error) => {});
  };

  const sendCoinsRequest = async (data) => {
    setIsLoading(true);
    try {
      let response = await withDrawCoins(data);
      if (response.status === 200) {
        reset();
        handleCloseSellCoins();
        toast.success("Your withdraw request was successful");
      }
    } catch (error) {
      if (error.response.data.Message) {
        toast.error(error.response.data.Message);
      } else {
        toast.error("server error try later!");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="col-md-6 col-lg-3 col-xl-3 py-4">
        <div className="buy-coin-card">
          <div className="buy-coin-inner-card mb-3">
            <div className="bagde-area">
              <p className="badge-text">{Props.badgeValue}</p>
            </div>
            <div className="text-center buy-coin-package-img">
              <img src={Props.cardImg} className="img-fluid" alt="wgc"></img>
            </div>
          </div>
          <div className="text-center">
            {Props.badgeValue.toLowerCase() === "buy" ? (
              <>
                <Link
                  to="/buy-credits"
                  className="btn btn-warning-bg btn-primary-cs w-250 h-74 my-auto buy-coin-btn"
                >
                  <span>{Props.badgeValue} Now</span>
                  {Props.badgeValue.toLowerCase() === "buy" && (
                    <>
                      <small className="d-block package-buy-small">
                        Pay Using Coin Payment
                      </small>
                    </>
                  )}
                </Link>
              </>
            ) : (
              <>
                <button
                  className="btn btn-warning-bg btn-primary-cs w-250 h-74 my-auto"
                  onClick={() => {
                    handleShowSellCoinsModal();
                  }}
                >
                  <span>{Props.badgeValue} Now</span>
                  {Props.badgeValue.toLowerCase() === "buy" && (
                    <>
                      <small className="d-block package-buy-small">
                        Pay Using Coin Payment
                      </small>
                    </>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showSellCoinsModal}
        onHide={handleCloseSellCoins}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
        className=""
        scrollable
      >
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="px-5 pb-5">
          {Props.badgeValue.toLowerCase() === "sell" && (
            <>
              <div>
                <h1 className="banner-title login-heading mb-3 font-trajan text-center">
                  Coming soon
                </h1>
                <p className="text-center banner-desc text-light">
                  This feature is coming soon
                </p>
              </div>
            </>
          )}
          {(Props.badgeValue.toLowerCase() === "send" || Props.badgeValue.toLowerCase() === "withdraw") && (
            <>
              <h1 className="banner-title login-heading mb-4 font-trajan text-center">
                How many coins to send?
              </h1>
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control rounded-0 form-control-lg"
                        placeholder="Enter Amount"
                        {...register("amount", {
                          required: "Amount is required",
                        })}
                      ></input>
                      {errors?.amount?.message && (
                        <div style={errorStyle}>{errors.amount.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control rounded-0 form-control-lg"
                        placeholder="Enter username or address"
                        {...register("address", {
                          required: "Username or address is required",
                        })}
                      ></input>
                      {errors?.address?.message && (
                        <div style={errorStyle}>{errors.address.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <textarea
                        type="textarea"
                        className="form-control rounded-0 form-control-lg"
                        rows="4"
                        placeholder="Add Note"
                        {...register("note")}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <small className="text-warning d-flex align-items-center mb-3">
                      <GrCircleAlert
                        size="32"
                        className="text-warning me-2 alert-send-coins"
                      />
                      Withdrawal address must be a valid BSC address,
                      withdrawals will be sent as USDT.BEP20 tokens.
                    </small>

                    {authData.kyc === null ? (
                      <>
                        <small className="text-warning d-flex align-items-center mb-3">
                          <GrCircleAlert
                            size="32"
                            className="text-warning me-2 alert-send-coins"
                          />
                          Please complete your KYC
                        </small>
                        <h4 className="text-white text-center mb-3">
                          Click here to{" "}
                          <span
                            className="text-warning verify-kyc-link"
                            onClick={() => {
                              handleShowKYCVerifycationModal();
                            }}
                          >
                            Verify
                          </span>
                        </h4>
                      </>
                    ) : authData?.kyc?.status === "Pending" ? (
                      <h6 className="text-white text-center mb-5">
                        Your KYC is being reviewed.
                      </h6>
                    ) : authData?.kyc?.status === "Rejected" ? (
                      <>
                        <h6 className="text-white text-center mb-5">
                          Your KYC is rejected. Reason:{" "}
                          {authData?.kyc?.rejectReason}
                        </h6>

                        <h4 className="text-white text-center mb-3">
                          <span
                            className="text-warning verify-kyc-link"
                            onClick={() => {
                              handleShowKYCVerifycationModal();
                            }}
                          >
                            Verify again
                          </span>
                        </h4>
                      </>
                    ) : (
                      authData?.kyc?.status === "Approved" && (
                        <h6 className="text-white text-center mb-5">
                          Your KYC is approved
                        </h6>
                      )
                    )}

                    <div className="w-100">
                      <button
                        onClick={handleSubmit(sendCoinsRequest)}
                        type="submit"
                        className="btn btn-dark rounded-0 w-100 btn-lg"
                        disabled={isLoading}
                      >
                        REQUEST{" "}
                        {isLoading && (
                          <ColorRing
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={[
                              "white",
                              "white",
                              "white",
                              "white",
                              "white",
                            ]}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
          {Props.badgeValue.toLowerCase() === "receive" && (
            <>
              <div>
                <h1 className="banner-title login-heading mb-3 font-trajan text-center">
                  Receive WGC
                </h1>
                <p className="text-center banner-desc text-light">
                  Send WGC.BEP20 to this address to receive coins on our
                  platform. If you send to another user on this platform, the
                  deposit will arrive instantly.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="text"
                    className="form-control disabled"
                    value={authData.external_deposit_address}
                    id="user-name-copy"
                    disabled
                    ref={copyRef}
                  />
                  <button
                    className=" ms-1 btn btn-icon text-white"
                    onClick={copyToClipboard}
                  >
                    <AiFillCopy />
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showKYCVerifycationModal}
        onHide={handleCloseKYCVerifycationModal}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
        className=""
        scrollable
      >
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="px-5 pb-5">
          <h1 className="banner-title login-heading mb-4 font-trajan text-center text-secoundar-cs">
            Verify your KYC
          </h1>
          <div>
            <KycVerification />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BuyCoinsCard;
