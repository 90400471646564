import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsArrowRight } from "react-icons/bs";
function WGC() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How do withdrawals work?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                You can enter the coin wallet address within the game and send
                it to an external wallet or another players wallet. If the coin
                is listed on an exchange, users can send coins directly from the
                game or the website to the exchange wallet for cashing out or
                purchasing other cryptocurrency (if paired ).
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How do purchases work?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                You can make direct WGC coin purchase within the game or through
                the WGC website using various payment options including
                cryptocurrency or visa/master card (optional, based on geo)
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default WGC;
