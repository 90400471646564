import React from "react";
import CareerCards from "./components/CareerCards";
import TalentWanted from "./components/TalentWanted";
import Select from "react-select";
import JobOppertunityCard from "./components/JobOppertunityCard";
function CareerPage() {
  const jobOptions = [
    { value: "Job 1", label: "Job 1" },
    { value: "Job 2", label: "Job 2" },
    { value: "Job 3", label: "Job 3" },
  ];
  return (
    <>
      <div className="container-fluid career-banner">
        <div className="container">
          <h1 className="banner-title mb-2 font-trajan">Talent Wanted</h1>
          <p className="banner-desc ">We are looking for you. Come Join Us!</p>
        </div>
      </div>
      <div className="buy-coins-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <TalentWanted content="Do you love gaming and want to help build the best metaverse that also helps other people gain value for themselves?" />

            <TalentWanted content="We pay top market rates in the industry because we want to work with the bests" />

            <TalentWanted content="We believe in benefiting others and the world. We are working towards providing 3 million jobs for gamers. Donate a large percentage of our profits to charitable causes" />
          </div>
        </div>
      </div>
      <div className="buy-coins-why-wgc">
        <div className="container mb-4">
          <div className="row">
            <div className="col-12">
              <h1 className="banner-title mb-2 font-trajan text-center">
                Join Us!
              </h1>
              <p className="banner-desc w-75 mx-auto text-center">
                If you're an autodidact and self-directed learner, we're an
                organization that believes in education and growth of all
                members of our tribe. Join us in one role, and we'll help grow
                your skills to your next steps towards creating the role of your
                dreams.
              </p>
            </div>
          </div>
        </div>
        <div className="best-moba-League-of-Legends-banner">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-6 text-center">
                <h1 className="banner-title mb-5 font-trajan text-left">
                  Game
                  <br />
                  Developers
                </h1>
                <p className="banner-desc mx-auto text-left">
                  Do you have an awesome game and want to take part in the
                  future of the metaverse? Plug in!
                </p>
                <p className="banner-desc mx-auto text-left">
                  Any game which has an element of PvP (player versus player)
                  can be used with our WGC Gem API. All players can put some
                  gems into the pot, the winner of the PvP takes amjority of the
                  gems in the pot, the second place a secondary portion, etc.
                  Amounts are determined by the game developers, but may not
                  exceed the maximum number of gems in the pot.
                </p>
              </div>
              <div className="col-md-6 text-center">
                <img
                  src="/assets/images/side-img.png"
                  className="img-fluid"
                  alt="wgc-thumbnial"
                ></img>
              </div>
            </div>
            <div className="row ">
              <CareerCards
                content="WGC is a virtual currency in the platform. They are only obtained by exchanging real currency through the WGC platform or its partner exchanges. They can also be cashed out through the platform. The game developers only need to take care of the gem transactions based on gaming logic."
                cardImg="/assets/images/career-card-img1.png"
              />
              <CareerCards
                content="In real-cash-gaming, games that have a concept of “house”, they are treated as a regular user by the platform. It is up to the game to implement its rules. For example, the “house” can be a company account, which will play in each game. Winnings go to the “house”, but losses are taken from the “house” account."
                cardImg="/assets/images/career-card-img2.png"
              />
              <CareerCards
                content="Even games that are single player can take advantage of your social platform login + in-app purchase API"
                cardImg="/assets/images/career-card-img3.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="how-buy-wgc-wrapper">
        <div className="container">
          <div className="row mb-4">
            <div className="col-12 mb-3">
              <h1 className="banner-title mb-3 font-trajan text-left">
                Job <br /> Opportunities
              </h1>
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col-md-4">
              <div className="mb-4">
                <Select
                  options={jobOptions}
                  placeholder="Enter the Job Title, Skill or City"
                  isClearable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-4">
                <button className="btn btn-blue">Reset Filter</button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="w-50 mb-4 text-start">
                <label className="form-label-cs">View</label>
                <Select
                  options={jobOptions}
                  placeholder="8 per page"
                  isClearable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className=" mb-4 text-start">
                <label className="form-label-cs">Country/Region</label>
                <Select options={jobOptions} placeholder="All" isClearable />
              </div>
            </div>
            <div className="col-md-4">
              <div className=" mb-4 text-start">
                <label className="form-label-cs">Job Category</label>
                <Select options={jobOptions} placeholder="All" isClearable />
              </div>
            </div>
            <div className="col-md-4">
              <div className=" mb-4 text-start">
                <label className="form-label-cs">Experience Level</label>
                <Select options={jobOptions} placeholder="All" isClearable />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <button className="btn btn-blue">Search Job</button>
                <p className="mb-0" style={{ color: "#939393" }}>
                  Search Result: 2
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <JobOppertunityCard
              content="WGC is a virtual currency in the platform. They are only obtained by exchanging real currency through the WGC platform or its partner exchanges. They can also be cashed out through the platform. The game developers only need to take care of the gem transactions based on gaming logic."
              cardImg="/assets/images/career-card-img1.png"
            />
            <JobOppertunityCard
              content="In real-cash-gaming, games that have a concept of “house”, they are treated as a regular user by the platform. It is up to the game to implement its rules. For example, the “house” can be a company account, which will play in each game. Winnings go to the “house”, but losses are taken from the “house” account."
              cardImg="/assets/images/career-card-img2.png"
            />
          </div>
        </div>
      </div>

      {/* <div className="">
        <AboutTabs />
      </div> */}
    </>
  );
}

export default CareerPage;
