// import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import MainRaoutes from "./Routes";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authUserAction } from "./redux/slices/AuthSlice";
// import { axiosInstance } from "./utility/interceptor";
import { toast, ToastContainer } from "react-toastify";
import { logoutAction } from "./redux/slices/AuthSlice";

function App() {
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const logout = () => {
    toast.error("session expired, login again");
    localStorage.removeItem("userdata-sgc");
    localStorage.removeItem("sgc-token");
    localStorage.removeItem("exp");
    dispatch(logoutAction());
    setTimeout(() => {
      navigator("/");
    }, 1000);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (localStorage.getItem("sgc-token")) {
      let exp = localStorage.getItem("exp");
      if (exp < Date.now()) {
        logout();
      } else {
        dispatch(
          authUserAction(JSON.parse(localStorage.getItem("userdata-sgc")))
        );
      }
    }
  }, [logout, dispatch]);

  return (
    <>
      <div className="App">
        <MainRaoutes />
      </div>

      <ToastContainer />
    </>
  );
}

export default App;
