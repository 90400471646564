import React, { useEffect, useState } from "react";
import BuyCoinsCard from "./components/BuyCoinsCard";
import { BsDot } from "react-icons/bs";
import { getRequest } from "../../utility/api";
import { ColorRing } from "react-loader-spinner";
import BuyCoinForm from "./utility/buyCoinForm";
import { useSelector } from "react-redux";

function BuyCoins() {
  const [coinRate, setCoinRate] = useState({});
  const [loading, setLoading] = useState(false);
  const { isLogin: isUserLogin } = useSelector((state) => state.auth);

  // getCoinRates
  const getCoinRates = async () => {
    setLoading(true);
    try {
      let { data } = await getRequest("/market/rates");
      setCoinRate(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect
  useEffect(() => {
    getCoinRates();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ColorRing
          visible={true}
          height="100"
          width="100"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["white", "white", "white", "white", "white"]}
        />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid buy-coins-banner">
        <div className="container">
          <h1 className="banner-title mb-2 font-trajan">
            WGC Game<br></br>
            Credits
          </h1>
          <p className="banner-desc ">
            All games are free to play. But where is the fun in that? Starting
            as low as $10, you can play against others and win WGC, and lots of
            it! Games can be as easy as one button touch, or advanced as FPS and
            strategy. One login can give you access to all games. Get your credits
            and may the best player win!
          </p>
        </div>
      </div>
      <div className="buy-coins-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <BuyCoinsCard
              badgeValue="10"
              cardImg="/assets/icons/coin_1.png"
              packageName="Bronze"
              packagePrice={`$${coinRate.sgc * 10}`}
              isUserLogin={isUserLogin}
            >
              <BuyCoinForm amount={coinRate.sgc * 10} />
            </BuyCoinsCard>

            <BuyCoinsCard
              badgeValue="50"
              cardImg="/assets/icons/coin_2.png"
              packageName="Silver"
              packagePrice={`$${coinRate.sgc * 50}`}
              isUserLogin={isUserLogin}
            >
              <BuyCoinForm amount={coinRate.sgc * 50} />
            </BuyCoinsCard>

            <BuyCoinsCard
              badgeValue="100"
              cardImg="/assets/icons/coin_3.png"
              packageName="Gold"
              packagePrice={`$${coinRate.sgc * 100}`}
              isUserLogin={isUserLogin}
            >
              <BuyCoinForm amount={coinRate.sgc * 100} />
            </BuyCoinsCard>

            <BuyCoinsCard
              badgeValue="1000"
              cardImg="/assets/icons/coin_4.png"
              packageName="Platinum"
              packagePrice={`$${coinRate.sgc * 1000}`}
              isUserLogin={isUserLogin}
            >
              <BuyCoinForm amount={coinRate.sgc * 1000} />
            </BuyCoinsCard>

            <BuyCoinsCard
              badgeValue="10000"
              cardImg="/assets/icons/coin_5.png"
              packageName="Diamond"
              packagePrice={`$${coinRate.sgc * 10000}`}
              isUserLogin={isUserLogin}
            >
              <BuyCoinForm amount={coinRate.sgc * 10000} />
            </BuyCoinsCard>
            <BuyCoinsCard
              badgeValue="50000"
              cardImg="/assets/icons/coin_6.png"
              packageName="Rhodium"
              packagePrice={`$${coinRate.sgc * 50000}`}
              isUserLogin={isUserLogin}
            >
              <BuyCoinForm amount={coinRate.sgc * 50000} />
            </BuyCoinsCard>
          </div>
        </div>
      </div>
      {/* <div className="buy-coins-why-wgc">
        <div className="container mb-4">
          <div className="row">
            <div className="col-12">
              <h1 className="banner-title mb-2 font-trajan text-center">
                Why WGC?
              </h1>
              <p className="banner-desc w-75 mx-auto text-center">
                WGC$ are available for purchase on the website or on partner
                exchanges. Look back frequently to keep up to date on added
                exchanges.
              </p>
            </div>
          </div>
        </div>
        <div className="why-wgc-banner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <h1 className="banner-title download-play-title font-trajan text-center mb-4">
                  Buy/Sell WGC
                </h1>
              </div>
              <div className="col-sm-4 text-center mb-2 mb-sm-0">
                <h4 className="font-trajan h4-cs-style">Vendor</h4>
                <img
                  src="/assets/images/dolce-game.png"
                  className="img-fluid"
                  alt="wgc-thumbnial"
                ></img>
              </div>
              <div className="col-sm-4 text-center mb-2 mb-sm-0">
                <h4 className="font-trajan h4-cs-style">swap</h4>
                <img
                  src="/assets/images/pancake-game.png"
                  className="img-fluid"
                  alt="wgc-thumbnial"
                ></img>
              </div>
              <div className="col-sm-4 text-center mb-2 mb-sm-0">
                <h4 className="font-trajan h4-cs-style">Exchange</h4>
                <img
                  src="/assets/images/sato-game.png"
                  className="img-fluid"
                  alt="wgc-thumbnial"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-buy-wgc-wrapper">
        <div className="container">
          <div className="row mb-4">
            <div className="col-12 mb-5">
              <h1 className="banner-title mb-1 font-trajan text-center">
                How to Buy/Sell<br></br> WGC Guide
              </h1>
              <div className="text-center">
                <a href="javascript:void(0)">Download PDF</a>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column mb-5">
              <h4 className="font-trajan h4-cs-style steps-border text-left">
                Step 1
              </h4>
              <div className="flex-cs">
                <p className="text-white mb-3 step-text-list">
                  <span>
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span className="text-left">
                    {" "}
                    Go to <b>“Buy Credits”</b> section in main page,
                  </span>
                </p>
              </div>
              <div>
                <img
                  src="/assets/images/step-1.png"
                  className="img-fluid"
                  alt="wgc-thumbnial"
                ></img>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column mb-5">
              <h4 className="font-trajan h4-cs-style steps-border text-left">
                Step 2
              </h4>
              <div className="flex-cs">
                <p className="text-white mb-3 step-text-list">
                  <span>
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span className="text-left">
                    There you will see <b>WGC Packages,</b> You can select your
                    package
                  </span>
                </p>
              </div>
              <div>
                <img
                  src="/assets/images/step-2.png"
                  className="img-fluid"
                  alt="wgc-thumbnial"
                ></img>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column mb-5">
              <h4 className="font-trajan h4-cs-style steps-border text-left">
                Step 3
              </h4>
              <div className="flex-cs">
                <p className="text-white mb-3 step-text-list">
                  <span>
                    <BsDot size={50} className="dot-color" />
                  </span>
                  <span className="text-left">
                    You can purchase the selected package through our website
                    directly or on partner exchange (Partner exchanges to be
                    announced later. Look back frequently to keep up to date on
                    added exchanges)
                  </span>
                </p>
              </div>
              <div>
                <img
                  src="/assets/images/step-1.png"
                  className="img-fluid"
                  alt="wgc-thumbnial"
                ></img>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12">
              <h4 className="font-trajan h4-cs-style steps-border mb-5 text-left">
                Video Tutorial
              </h4>

              <div className="text-center">
                <img
                  src="/assets/images/video-placeholder.png"
                  className="img-fluid"
                  alt="wgc-thumbnial"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="">
        <AboutTabs />
      </div> */}
    </>
  );
}

export default BuyCoins;
