import React from "react";
import Chart from "react-apexcharts";
const areaColors = {
  series1: "#cb3de9",
  series2: "#0aefdf",
};
function SaleReport(props) {
  const saleDate = props.saleDate;
  const sortedSalesData = props.sortedSalesData;

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    // stroke: {
    //   width: [5, 7, 5],
    //   curve: "straight",
    //   // dashArray: [0, 8, 5],
    // },
    title: {
      text: "Sale Report",
      align: "left",
    },
    colors: [areaColors.series2, areaColors.series1],
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: saleDate,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              // return val + " (mins)";
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#404040",
      textColor: "#404040",
    },
  };

  // ** Chart Series
  const series = [
    {
      name: "Sponser Count",
      data: sortedSalesData?.map((item) => item.count),
    },
    {
      name: "Earnings",
      data: sortedSalesData?.map((item) => item.earnings),
    },
  ];
  return (
    <>
      <div className="bg-light-grey-cus">
        <Chart options={options} series={series} type="line" height={400} />
      </div>
    </>
  );
}

export default SaleReport;
