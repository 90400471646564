import React from "react";

function CommonBanner(props) {
  let { banner, icon, thumbnail } = props.game;
  return (
    <>
      <div className="main-pages-banner p-relative">
        <img
          src={banner}
          className="img-fluid w-100 main-banner-game-insight"
          alt="wgc-thumbnial"
        ></img>
        <div className="container">
          <img
            src={thumbnail}
            className="img-fluid title-strike"
            width="300px"
            alt="wgc-thumbnial"
          ></img>
          <img
            src={icon}
            className="img-fluid beta-game"
            alt="wgc-thumbnial"
          ></img>
        </div>
      </div>
    </>
  );
}

export default CommonBanner;
