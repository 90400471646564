import axios from "axios";
import { toast } from "react-toastify";
import { ApiBaseUrl } from "../constants/endpoints";
import { logoutAction } from "../redux/slices/AuthSlice";
// import { JWTTokenConfig } from "../constants/jwt";
import { store } from "../redux/store";

export const axiosInstance = axios.create({
  baseURL: ApiBaseUrl,
});

// axiosInstance.interceptors.request.use(
//     function (config) {
//         const token = getAuthToken();
//   var organizationID=Common.getUserCurrentOrganizatioId();
//         if (token != null) {
//             config.headers.Authorization = token;
//             config.headers.OrganizationID = organizationID;

//         }

//         return config;
//     },
//     function (err) {
//         return Promise.reject(err);
//     }
// );

const UNAUTHORIZED = 401;
const { dispatch } = store; // direct access to redux store.

// // Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    try {
      if (error.response.status === UNAUTHORIZED) {
        toast.warning("Session Expired");
        localStorage.clear();
        dispatch(logoutAction());
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    } catch (ex) {
      // Logout("apid");
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
