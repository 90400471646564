import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { BsArrowRight } from "react-icons/bs";
function RewardProgram() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <Accordion defaultActiveKey={["0"]}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What is RP?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                RP Stands for Rewards Points, a loyalty program at WGC Games
                Platform,
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                How can I earn RP?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  RP cannot be purchased. Gamers cannot withdraw, sell RP for
                  anything of cash value unless redeemed for WGC based on market
                  ratio in our flagship games.
                </p>
                <p>
                  RP is awarded to players for competing in all WGC enabled
                  matches. The more you win, the more RP you can gain. Even you
                  lose your game, you will still get some RP as a reward for
                  your participation.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <BsArrowRight size="24" className="me-2" />
                What can I do with RP?
              </Accordion.Header>
              <Accordion.Body className="px-5 text-left">
                <p>
                  RP can be used to redeem for virtual or physical items or WGC
                  provided in the loyalty program page in some of our flagship
                  games.
                </p>
                <p>
                  Further along the line, we hope to provide the option for
                  swapping RP for physical prizes such as Esports accessories,
                  earphones, music players and more. Follow the updates for more
                  information.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default RewardProgram;
