import React from "react";

import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import AboutTabs from "./components/AboutTabs";
function About() {
  return (
    <>
      <div className="container-fluid about-banner">
        <div className="container">
          <h1 className="banner-title mb-5 font-trajan banner-title-border text-start">
            World Game Community
          </h1>
          <div className="padding-right-25 mb-5 text-start">
            <p className="banner-desc color-grey mb-5">
              World Game Community is a premium online game publisher based in
              the metaverse, owned and operated by a community of gamers with an
              emphasis on entertaining, social online games based on skill. All
              games are Free-To-Play, and ad free. You can purchase in-game
              items to boost your games or enter e-sports competitions by using
              WGC credits.
            </p>
            {/* <p className="banner-desc color-grey">
              Whitepaper is subject to updates based on market trends and
              circumstances, please ensure you obtain the latest whitepaper to
              stay current.
            </p> */}
          </div>
          {/* <div className="d-flex align-items-center flex-wrap">
            <button className="btn btn-primary-bg btn-primary-cs me-4 padding-btn">
              Whitepaper
              <span className="ms-2">
                <BsArrowRight />
              </span>
            </button>
            <span>
              <Link className="play-game-link" to="#">
                Download Whitepaper
              </Link>
            </span>
          </div> */}
        </div>
      </div>
      <div className="">
        <AboutTabs />
      </div>
    </>
  );
}

export default About;
