import { apiUrls as ApiUrls } from "../constants/endpoints";
import { axiosInstance } from "./interceptor";

export function getRequest(url) {
  return axiosInstance.get(url);
}
export function getRequestById(url, id) {
  return axiosInstance.get(url + "?id=" + id);
}

export function getRequestQry(url, stringQuery) {
  return axiosInstance.get(url + stringQuery);
}

export function postRequest(url, requestParameters, options = "") {
  if (options) {
    return axiosInstance.post(url, requestParameters, options);
  } else {
    return axiosInstance.post(url, requestParameters);
  }
}
export function postWithFilesRequest(url, requestParameters) {
  return axiosInstance.post(url, requestParameters, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "*/*",
    },
  });
}

export function deleteRequest(url, id) {
  return axiosInstance.delete(url + "/" + id);
}

export function postDeleteRequest(url, id) {
  return axiosInstance.post(url + "/" + id);
}

export function putRequest(url, requestParameters) {
  if (requestParameters) {
    return axiosInstance.put(url, requestParameters);
  } else {
    return axiosInstance.put(url);
  }
}
 
export const apiUrls = ApiUrls; 
