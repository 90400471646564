import axios from "axios";
import { ApiBaseUrl } from "../constants/endpoints";
import * as API from "../utility/api";
axios.defaults.baseURL = ApiBaseUrl;
// export async function getAllGames(range, offset = 0) {
//   var data = await API.getRequest(
//     API.apiUrls. + "?range=" + range
//     // API.apiUrls.games
//   );
//   return data;
// }
export function getAllGames(range) {
  return API.getRequest(API.apiUrls.getAllGames + "?range=" + range);
}
