import React from "react";
import Footer from "./Footer";
const HomeContactUs = () => {
  return (
    <div className="container">
      <div className="row align-items-center mb-5">
        <div className="col-lg-6">
          <h1 className="banner-title download-play-title font-trajan text-left mb-4">
            Contact Us
          </h1>
          <div>
            <ul>
              <li className="banner-desc text-left">Support Email</li>
              <a
                href="mailto:email@example.com"
                style={{ textDecoration: "none" }}
              >
                <li className="banner-desc text-left">
                  info@worldgamecommunity.com
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div className="col-lg-6">
          <form>
            <div className="form-group d-flex contact-us-section-form">
              <input
                type="text"
                placeholder="EMAIL"
                className="form-control me-3 form-control-cs mb-2 mb-sm-0"
              ></input>
              <a
                href={`mailto:info@worldgamecommunity.com`}
                className="btn btn-dark-cs"
              >
                Send
              </a>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default HomeContactUs;
