import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { stripe_session } from "../../../services/stripeService";

function BuyCoinForm({ amount }) {
  
  const location = window.location.href;
  const baseUrl = location.split("/buy-credits")[0];

  const { handleSubmit } = useForm();

  const onConfirmation = async (d, e) => {
    let url = "/webhook/stripe-create-checkout-session";
    let data = {
      sgc_amount: parseInt(amount),
      success_url: window.location.origin + "/payment-success",
      cancel_url: window.location.origin + "/payment-decline",
    };
    try {
      let result = await stripe_session(data, url);
      if (result) {
        if (result.data.payment_url) {
          window.location.href = result.data.payment_url;
        }
      }
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.Message);
      }
    }
  };
  return (
    <>
      <p style={{color: 'white'}}>Important: Please use the same email in your payment as the one in your WGC account.</p>
      <form
        action="https://www.coinpayments.net/index.php"
        method="post"
        target="_blank"
      >
        <input type="hidden" name="cmd" value="_pay" />
        <input type="hidden" name="reset" value="1" />
        <input
          type="hidden"
          name="merchant"
          value="1c5ecd96fd5f30bd6f80467d69579d25"
        />
        <input type="hidden" name="item_name" value={`$${amount} Pack`} />
        <input type="hidden" name="currency" value="USD" />
        <input type="hidden" name="amountf" value={amount * 1.1} />
        <input type="hidden" name="quantity" value="1" />
        <input type="hidden" name="allow_quantity" value="0" />
        <input type="hidden" name="want_shipping" value="0" />
        <input
          type="hidden"
          name="success_url"
          value={`${baseUrl}/payment-success`}
        />
        <input
          type="hidden"
          name="cancel_url"
          value={`${baseUrl}/payment-decline`}
        />
        <input
          type="hidden"
          name="ipn_url"
          value="https://wgcapi.mmocircles.com/webhook/coinpayments-ipn"
        />
        <input type="hidden" name="allow_extra" value="0" />
        <label
          htmlFor={`submit${amount}`}
          className="btn btn-warning-bg btn-primary-cs w-250 my-auto"
        >
          <span>Buy Now</span>
          <small className="d-block package-buy-small">
            Pay Using Coin Payment
          </small>
        </label>
        <input
          id={`submit${amount}`}
          type="image"
          src="https://www.coinpayments.net/images/pub/buynow-grey.png"
          alt="Buy Now with CoinPayments.net"
          style={{ display: "none" }}
        />
      </form>
      <br />
      <form onSubmit={handleSubmit(onConfirmation)}>
        <button
          type="submit"
          id={`submit${amount}`}
          className="btn btn-warning-bg btn-primary-cs w-250 my-auto"
        >
          <label htmlFor={`submit${amount}`} type="submit">
            <span>Buy Now</span>
            <small className="d-block package-buy-small">
              Pay using Stripe
            </small>
          </label>
        </button>
      </form>
    </>
  );
}

export default BuyCoinForm;
